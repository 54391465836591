<template>
    <Layout>
        <v-container class="d-flex justify-center my-md-14 my-7">
            <div v-if="$config.mobile()" style="max-width: 350px">
                <v-col cols="12" md="5" class="d-flex justify-center align-center">
                    <div v-if="this.$route.query.token" class="animate__animated animate__fadeIn animate__delay-1s">
                        <div>
                            <h1 v-once v-html="$t_('confirm-email')"></h1>
                            <img src="@/assets/images/email_confirmado.jpg" width="350"/>
                            <v-btn x-large class="mr-md-3 my-5" :loading="$usuario.cadastro.loading" style="color: white" :color="$config.dados.corprimaria" @click="goHome()" block elevation="0" tabindex="3"><strong>{{$t_('confirm-email-btn')}}</strong></v-btn>
                        </div>
                    </div>
                    <div v-else class="animate__animated animate__fadeIn animate__delay-1s">
                        <div>
                            <h1 v-once v-html="$t_('token-invalido')" style="border-bottom: 3px solid #f04c2b;"></h1>
                            <img src="@/assets/images/token_invalido.png" width="350" class="my-5"/>
                            <v-btn x-large class="mr-md-3 my-5" :loading="$usuario.cadastro.loading" style="color: white" :color="$config.dados.corprimaria" @click="goHome()" block elevation="0" tabindex="3"><strong>{{$t_('confirm-email-btn')}}</strong></v-btn>
                        </div>
                    </div>
                </v-col>
            </div>
            <template v-else>
                <v-col cols="12" md="5" class="d-flex justify-center align-center">
                    <div v-if="this.$route.query.token" class="animate__animated animate__fadeIn animate__delay-1s">
                        <div>
                            <h1 v-once v-html="$t_('confirm-email')"></h1>
                            <img src="@/assets/images/email_confirmado.jpg" width="400" class="my-5"/>
                            <v-btn x-large class="mr-md-3 my-5" :loading="$usuario.cadastro.loading" style="color: white" :color="$config.dados.corprimaria" @click="goHome()" block elevation="0" tabindex="3"><strong>{{$t_('confirm-email-btn')}}</strong></v-btn>
                        </div>
                    </div>
                    <div v-else class="animate__animated animate__fadeIn animate__delay-1s">
                        <div>
                            <h1 v-once v-html="$t_('token-invalido')" style="border-bottom: 3px solid #f04c2b;"></h1>
                            <img src="@/assets/images/token_invalido.png" width="400" class="my-5"/>
                            <v-btn x-large class="mr-md-3 my-5" :loading="$usuario.cadastro.loading" style="color: white" :color="$config.dados.corprimaria" @click="goHome()" block elevation="0" tabindex="3"><strong>{{$t_('confirm-email-btn')}}</strong></v-btn>
                        </div>
                    </div>
                </v-col>
            </template>
        </v-container>
    </Layout>
</template>
<script>
    export default {
        name: 'ConfirmEmail.vue', 
        components: {
            Layout: () => import('@/layout/Default.vue')
        },
        data() {
            return {
                show1: false,
            }
        },
        methods: {
            goHome() {
                this.$router.push({ name: 'Home' })
            },
            getParams() {
                if(this.$route.query.token) {
                    setTimeout(() => {
                        this.$router.push({ name: 'Home' })
                    }, 5000)
                } 
            }   
        },
        mounted() {
            this.getParams()    
        }  
    }
</script>